import React from 'react';
import Messages from './MessageWidget';
import Calendar from './CalendarWidget';
//import Gigs from 'GigsWiget';
import { Link } from "react-router-dom";
import {useUser} from './UserContext';
import flexygig from '../assets/images/gigs.ico';

import '../styles/WorkerDashboard.css';

const WorkerDashboard = () => {
    const {user} = useUser();

    return (
        <div className="dashboard-container">
          <header className="dashboard-header">
            {/* Welcome message with user's name */}
            <h1 className="dashboard-welcome">
              Hello, {user ? `${user.firstname} ${user.lastname}` : "User"}
            </h1>
            {/* Flexygig name and logo */}
            <div className="logo-container">
              <Link to="/" className="logo-link">
                <img src={flexygig} alt="Flexygig Logo" className="dashboard-logo" />
                <h1 className="dashboard-logo-name">Flexygig</h1>
              </Link>
            </div>
          </header>
          
          <main className="dashboard-content">

            {/* Left Side - Main Sections */}
            <section className="dashboard-left">
              <div classname="messages-section">
                <h3>Messages</h3>
                <Messages />
              </div>
              {/* <Gigs and messages go in here/> */}
            </section>
    
            {/* Right Side - Summary and Calendar */}
            <aside className="dashboard-right">

              <div className="october-summary">
                <h3>October Summary</h3>
                <p>Gigs Posted: <strong>2 gigs</strong></p>
                <p>Rating: <strong>4/5</strong></p>
              </div>
    
              <div className="calendar-section">
                <h3>Calendar</h3>
                <Calendar />
              </div>
            </aside>
          </main>
        </div>
      );
};

export default WorkerDashboard;