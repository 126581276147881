import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Register.css";

const Register = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    accountType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData({ ...registrationData, [name]: value });
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    // ensure Account Type is selected
    if (registrationData.accountType == "") {
      setErrorMessage(
        "Please make sure you have selected an account type."
      );
      return;
    }

    // Password validation
    const capitalRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const minLength = 8;

    if (
      registrationData.password.length < minLength ||
      !capitalRegex.test(registrationData.password) ||
      !numberRegex.test(registrationData.password) ||
      !specialCharRegex.test(registrationData.password)
    ) {
      setErrorMessage(
        "Password must be at least 8 characters long and contain 1 capital letter, 1 number, and 1 special character."
      );
      return;
    }

    try {
      const response = await axios.post("/api/register", registrationData);

      // Clear input fields
      setRegistrationData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      });

      // TODO: navigate to /registration-success, not /verify when verification email is being sent
      // navigate("/registration-success");
      navigate("/verify/" + response.data.token);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.response.data);
      }
    }
  };

  return (
    <div className="center-container">
      <h2>Flexygig</h2>
      <h2>Register</h2>
      <form onSubmit={handleRegistration}>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            name="firstName"
            value={registrationData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={registrationData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="registerEmail">Email:</label>
          <input
            type="email"
            name="email"
            value={registrationData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="registerPassword">Password:</label>
          <input
            type="password"
            name="password"
            value={registrationData.password}
            onChange={handleChange}
            required
          />
        </div>

        {/* Select if your new account is a Gig Worker or Employer */}
        <div>
          <input type="radio" id="worker" name="accountType" value="Gig Worker" onChange={handleChange}/>
          <label for="worker">I am a Gig Worker</label> 
          <input type="radio" id="employer" name="accountType" value="Employer" onChange={handleChange}/>
          <label for="employer">I am an Employer</label>
        </div>

        <button type="submit">Register</button>
      </form>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <p>
        Already have an account?
        <Link
          to="/signin"
          style={{
            marginLeft: "5px",
            color: "#4CAF50",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Sign In
        </Link>
      </p>
    </div>
  );
};
export default Register;
