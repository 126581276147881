import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Calendar from "./components/Calendar";
import GigWorkersPage from "./components/GigWorkersPage";
//import Header from "./components/Header";
//import HomePage from "./components/HomePage";
import JobPostingForm from "./components/JobPostingForm";
import LandingPage from "./components/LandingPage";
import MyJobs from "./components/MyJobs";
import PasswordReset from "./components/PasswordReset";
import PasswordResetInitiation from "./components/PasswordResetInitiation";
import ProfilePage from "./components/ProfilePage";
import Register from "./components/Register";
import RegistrationSuccess from "./components/RegistrationSuccess";
import SignIn from "./components/SignIn";
import { UserProvider } from "./components/UserContext";
import VerifyEmailPage from "./components/VerifyEmail"; 
import WorkerDashboard from "./components/WorkerDashboard";
import EmployerDashboard from "./components/EmployerDashboard";
// Custom
import Layout from "./components/Layout";

const App = () => {
  const [user, setUser] = useState(
    () => JSON.parse(localStorage.getItem("user")) || null
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedUserData = localStorage.getItem("user");
      if (updatedUserData) {
        setUser(JSON.parse(updatedUserData));
      }
    };

    // Listen for localStorage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up the event listener
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/verify/:token" element={<VerifyEmailPage />} />
          <Route path="/initiate-password-reset" element={<PasswordResetInitiation />} />
          <Route path="/verify/password-reset/:uniqueIdentifier" element={<PasswordReset />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />

          {/* Protected Routes with Layout */}
          <Route element={<Layout />}>
            <Route path="/worker-dashboard" element={<WorkerDashboard />} />
            <Route path="/employer-dashboard" element={<EmployerDashboard />} />
            {/*  <Route path="/home" element={<HomePage />} />  */}
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/my-jobs" element={<MyJobs />} />
            <Route path="/create-job" element={<JobPostingForm />} />
            <Route path="/edit-job" element={<JobPostingForm />} />
            <Route path="/gig-workers" element={<GigWorkersPage />} />
            <Route path="/my-calendar" element={<Calendar />} />
          </Route>
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
