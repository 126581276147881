// Toolbar.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import inboxIcon from "../assets/images/InboxIcon.ico";
import calendarIcon from "../assets/images/CalendarIcon.ico";
import messagesIcon from "../assets/images/ChatIcon.ico";
import plusIcon from "../assets/images/PlusSymbolIcon.ico";
import folderIcon from "../assets/images/folderIcon.ico";
import userIcon from "../assets/images/AvatarIcon.ico";
import homeIconThick from "../assets/images/HomeIconThick.ico";
import { useUser } from "./UserContext";

import "../styles/Toolbar.scss";

const Toolbar = () => {
  const { user, setUser } = useUser();
  const location = useLocation();

  // Helper function to determine if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="toolbar">
      {user.isbusiness ? (
                <Link to="/employer-dashboard" className={isActive("/employer-dashboard") ? "active" : ""}>
                <img src={homeIconThick} alt="Home" className="toolbar-icon" />
                </Link>
              ) : (
                <Link to="/worker-dashboard" className={isActive("/worker-dashboard") ? "active" : ""}>
                <img src={homeIconThick} alt="Home" className="toolbar-icon" />
                </Link>
              )}
      <Link to="/gig-workers" className={isActive("/gig-workers") ? "active" : ""}>
        <img src={inboxIcon} alt="Messages" className="toolbar-icon" />
      </Link> 
      <Link to="/my-calendar" className={isActive("/my-calendar") ? "active" : ""}>
        <img src={calendarIcon} alt="Calendar" className="toolbar-icon" />
      </Link>
      <Link to="/chats" className={isActive("/chats") ? "active" : ""}>
        <img src={messagesIcon} alt="Chats" className="toolbar-icon" />
      </Link>
      <Link to="/create-job" className={isActive("/create-job") ? "active" : ""}>
        <img src={plusIcon} alt="Create Job" className="toolbar-icon" />
      </Link>
      <Link to="/my-jobs" className={isActive("/my-jobs") ? "active" : ""}>
        <img src={folderIcon} alt="My Jobs" className="toolbar-icon" />
      </Link>
      <Link to="/profile" className={isActive("/profile") ? "active" : ""}>
        <img src={userIcon} alt="Profile" className="toolbar-icon" />
      </Link>
    </div>
  );
};

export default Toolbar;
