import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "./UserContext";

const VerifyEmailPage = (setUserData) => {
  const { setUser } = useUser();
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState({
    success: false,
    message: "",
  });

  useEffect(() => {
    axios
      .get(`/api/verify/${token}`)
      .then((response) => {
        // Set verification status based on the response
        setVerificationStatus({
          success: response.data.success,
          message: response.data.message || "Email verified successfully.",
        });

        console.log(response);

        if (response.status == 200) {
          console.log("setting the current user");
          let currentUser = response.data;
          currentUser.emailVerified = true;
          localStorage.setItem("user", JSON.stringify(currentUser));
          setUser(currentUser); // Update global user state
        }

        setTimeout(() => {
          if (response.data.isbusiness) {
            navigate("/employer-dashboard"); // Navigate to dashboard for Employers
          }
          else {
            navigate("/worker-dashboard"); // Navigate to dashboard for Gig Workers
          }
        }, 2000);
      })
      .catch((error) => {
        console.log("Error with verification", error);
        const errorMessage = error.response
          ? error.response.data.message
          : "Verification failed. Please try again.";
        setVerificationStatus({ success: false, message: errorMessage });
      });
  }, [token, navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Email Verification</h1>
      <p style={{ color: verificationStatus.success ? "green" : "green" }}>
        {verificationStatus.message}
      </p>
    </div>
  );
};

export default VerifyEmailPage;
