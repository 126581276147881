import "../styles/MessageWidget.css";

const MessageWidget = () => {
    // JS

    // get newest message from database
    const GetMessage = () => {
        // message structure: to, from, decriptor, text, time, read
        var message = [
            "current user",
            "Jenny Brewer",
            "Bartender",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam\
                interdum varius justo, sit amet tempus risus. Suspendisse eget\
                tempor tellus, eget luctus tellus. Nullam vestibulum vulputate\
                justo, sed malesuada metus dignissim et. Curabitur mattis\
                dapibus turpis vel ultrices. Suspendisse finibus metus a cursus\
                auctor.",
            "10:30 A.M.",
            "false"
        ];

        return message;
    };

    // store retrieved message
    const msg = GetMessage();

    // get required elements
    const sender = msg[1];
    const descriptor = msg[2];
    const text = msg[3];
    const time = msg[4];

    // HTML
    return (
        <div className="MessageWidget">
            <div className="header">
                <div id="person">
                    <p id="name">{sender}</p>
                    <p id="descriptor">{descriptor}</p>
                </div>
                <p id="time">{time}</p>
            </div>
            <div className="text">{text}</div>
        </div>
    );
};

export default MessageWidget;
